.bg {
  background: repeat-y center/100%;
  background-image: url(../../assets/fruit.jpg);
  color: rgb(47, 48, 47);
  margin: -20px;
  overflow-y: overlay;

  .request {
    color: rgb(47, 48, 47);
    margin: 30px 50px;

    form {
      margin: 20px auto;
      background-color: rgba(191, 175, 156, .4);
      border: 1px solid rgba(191, 175, 156, .8);
      text-align: center;
      padding: 20px;
      border-radius: 5px;
      max-width: 400px;

      label {
        color: rgb(47, 48, 47);
        font-size: 150%;
      }

      input[type="text"] {
        margin-top: 20px;
        width: 90%;
        border: 1px solid rgba(123, 156, 65, .8);
        padding: 5px;
        border-radius: 5px;
        font-size: 110%;
      }

      button {
        display: block;
        margin: 20px auto 0 auto;
        border: 1px solid rgba(153, 186, 95, .8);
        border-radius: 5px;
        color: #fff;
        background-color: rgb(153, 186, 95);
        font-size: 20px;
        padding: 5px 10px;
        cursor: pointer;
      }
      
      button:hover {
        border: 1px solid rgba(123, 156, 65, .8);
        color: #fff;
        background-color: rgb(123, 156, 65);
      }
    }

    .pantryButtons {
      margin: 0 auto;
      max-width: 400px;
    
      button {
        margin: 1%;
        font-size: 20px;
        padding: 5px 10px;
        border: 1px solid rgba(153, 186, 95, .8);
        border-radius: 5px;
        color: #fff;
        background-color: rgb(153, 186, 95);
      }
    
      button:hover {
        border: 1px solid rgba(123, 156, 65, .8);
        color: #fff;
        background-color: rgb(123, 156, 65);
      }
    }
    
    .pantryItems {
      margin: 0 auto;
      max-width: 400px;
      ul {
        list-style-type: none;
      }
    }
    
    .pantryRoute {
      margin: 0 auto;
      max-width: 400px;
    
      ul {
        list-style-type: none;
      }
    }
    
    .map {
      margin: 0 auto;
      max-width: 400px;
    }  

  }
}

@media screen and (max-width: 900px) {
  .bg{
    background: none;
  }
}

@media screen and (max-width: 480px) {
  .bg{
    .request {
      form {
        margin-left: -10px;
        margin-right: 10px;
      }
      .map {
        margin: 0 -50px;
      }
    }
  }

}

