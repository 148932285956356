// //Colors from Ryan's template
// $button-highlights: #4f6339;
// $primary-text:#493932;
// $secondery-color:#2e303e;
// $offwhite:#ece8d3;

//colors from George's template
//names should be changed based on the use of color 
$color-accent:#BD3B1B;
$color-lines:#D8A800;
$color-secondary:#B9D870;
$color-primary:#B6C61A;
$color-button-highlight:#006344;
$color-text:#2f302f;
$white:white;
$color-text-outline:rgb(211, 211, 211);



//Fonts
$font-stack-primary: 'Lato', sans-serif;;
$font-stack-secondary: 'Lato', serif;

//Box Shadow
$shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.19);


