@import '../styles/variables';

.footer {
  background-color: $color-secondary;
  height: 70px;
  padding-top: 5px;
  text-align: center;

  .name {
    color:white;
    font-size: 200%;
    display: block;
  }

  .rights {
    color:$color-button-highlight;
   }
}

@media screen and (min-width: 800px) {
  .footer {
    .name {
      display: inline-block;
      padding: 10px 20px;
    }
  }
}
