@import '../styles/variables';

@media screen and (min-width: 320px) {

  .container {
    display: grid;
    grid-template-columns: .9fr 1.1fr;
    grid-template-areas: 'left right';
    background-color: $color-text;
    color: $color-secondary;

    .title{
      display: none;
    }
  
    .nav{
      grid-area: right;
      padding: 1em 0 1em 0;
      

      .menu {
        display: none;
        font-size: 50px;
        border: none;
        background: none;
      }

      .list {
        display: none;
        padding-inline-start: 0px;
      }

      .menu:checked ~.list {
        display: block;
        
      }

      li {
        list-style-type: none;
        padding-top: 8px;

        a {
          color: $color-secondary;
          text-decoration: none;
    
          &:hover {
            color: white;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'left center right';

    .title {
      display: inline;
      grid-area: left;
      color: white;
      text-decoration: underline;
      text-decoration-color: #ed7d32;
      align-self: center;
      padding-left: 2em;
    }

    .nav {
      padding: 0;

      label {
        display: none;
      }

      .list {
        display: inline;
        display: grid;
        font-size: x-large;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: 'Home About Login SignUp';
        font-size: x-large;
      }

      .list:nth-child(1) {
        grid-area: Home;
      }
      .list:nth-child(2) {
        grid-area: About;
      }
      .list:nth-child(31) {
        grid-area: Login;
      }
      .list:nth-child(4) {
        grid-area: SignUp;
      }

      li {
        text-align: center;
      }
    }
  }
}