.drive {
  background: repeat-y center/100%;
  background-image: url(../../assets/fruit.jpg);
  color: rgb(47, 48, 47);
  margin: -10px;
  overflow-y: overlay;

  h2 {
    margin: 20px auto;
    max-width: 400px;
  }  
  
  .pantryItems {
    margin: 0 auto;
    max-width: 400px;

    ul {
      list-style-type: none;
      font-size: 18px;

      button {
        background-color: rgb(153, 186, 95);
        border: 1px solid rgba(153, 186, 95, .8);
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        margin: 5px 10px;
        padding: 5px 10px;
      }

      button:hover {
        background-color: rgb(123, 156, 65);
        border: 1px solid rgba(123, 156, 65, .8);
        color: #fff;
      }

      span {
        display: inline-block;
        text-align: center;
        width: 20px;
      }
    }
  }
  
  .pantryRoute {
    margin: 0 auto;
    max-width: 400px;
  
    ul {
      list-style-type: none;
    }
  }
  
  .map {
    margin: 20px auto;
    max-width: 400px;
  }  
}

@media screen and (max-width: 900px) {
  .drive{
    background: none;
  }
}

@media screen and (max-width: 480px) {
  .drive {
    margin: 0 30px;
  }
}
